<template>
  <div class="content">
    <div class="contentbox">
      <el-tabs  v-model="searchBox.activeName" @tab-click="handleClick">
        <el-tab-pane label="放款中" name="0"></el-tab-pane>
        <el-tab-pane label="放款完成" name="1"></el-tab-pane>
      </el-tabs>
      <div class="SearchBar">
        <div style="display: flex">
          <span style="margin: 6px 10px 0 20px ">工程编号/名称:</span>
          <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.numberOrName"></el-input>
          <span style="margin: 6px 10px 0 20px ">第三方施工队：</span>
          <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.teamName"></el-input>
          <el-button type="primary" class="SearchBtn" size="small" @click="search" style="margin-left: 10px">查询</el-button>
          <el-button type="primary" class="SearchBtn" size="small" @click="ext" style="margin-left: 10px">导出</el-button>
        </div>

      </div>
      <!--数据表格-->
      <el-table
          ref="tableData"
          :data="tableData"
          style="width: 100%;margin: 15px 0;"
          border
          height="calc(100vh - 394px)"
          :stripe="true">
        <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
        <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="teamName" label="安装施工队" align="center" show-overflow-tooltip></el-table-column>
<!--        <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip></el-table-column>-->
        <el-table-column prop="total" label="项目总工程款" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="paid" label="已支付施工费" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column v-if="searchBox.activeName==0" prop="unpaid" label="未支付施工费" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" align="left"  width="300">
          <template slot-scope="{row}">
            <el-button v-if="searchBox.activeName==0"  @click="add(row)" type="success" size="small">新增放款</el-button>
            <el-button  @click="showDetil(row)" type="primary" size="small">放款明细</el-button>
            <el-button v-if="searchBox.activeName==0"  @click="makesure(row)" type="success" size="small">放款完成</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageBox">
        <el-pagination
            :current-page="searchBox.current"
            :background="true"
            :page-sizes="[ 30, 50, 100]"
            :page-size="searchBox.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
        title="放款明细"
        :visible.sync="detildialogVisible"
        :close-on-click-modal="false"
        width="60%">
      <el-button type="primary" @click="exportFile">导出</el-button>
      <el-table
          ref="tableData"
          :data="detiltableData"
          style="width: 100%;margin: 15px 0;"
          border
           
          :stripe="true">
        <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
        <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="teamName" label="安装施工队" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="total" label="项目总工程款" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="payFees" label="支付施工费" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="payExplain" label="放款说明" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="realname" label="操作人" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createTime" label="操作时间" align="center" show-overflow-tooltip></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
        title="新增放款"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal="false"
        @close="closeFrom('formData')">
      <el-form ref="formData" :model="formData" label-width="180px" :rules="rule">
        <el-form-item label="工程名称：" prop="archiveNumber">
          <span>{{formData.projectName}}</span>
        </el-form-item>
        <el-form-item label="第三方施工队：" prop="archiveNumber">
          <span>{{formData.teamName}}</span>
        </el-form-item>
        <el-form-item label="项目总工程款：" prop="archiveNumber">
          <span>{{formData.total}}</span>
        </el-form-item>
        <el-form-item label="已支付施工费：" prop="paid">
          <span>{{formData.paid}}</span>
        </el-form-item>
        <el-form-item label="支付施工费：" prop="payFees">
          <el-input type="number" v-model="formData.payFees" @change="payMoney"></el-input>
        </el-form-item>
        <el-form-item label="本工程剩余施工费：" prop="archiveNumber">
          {{formData.payFees?Number(formData.total-formData.paid-formData.payFees).toFixed(2):(Number(formData.total-formData.paid).toFixed(2))}}
        </el-form-item>
        <el-form-item label="放款说明：" prop="payExplain">
          <el-input type="textarea" v-model="formData.payExplain" ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="sumit('formData')">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {paymentingList,paymentCompleteList,paymentRecord,paymentRecordExport,paymentSave,paymentComplete,paymentingListExport,paymentDoneListExport} from '../../RequestPort/cost/cost'
import { exportMethod } from '../../common/js/exportExcel'
export default {
  name: "dataMember",
  data(){
    return{
      tableData:[],
      detiltableData:[],
      dialogVisible:false,
      detildialogVisible:false,
      total:null,
      expordId:null,
      searchBox:{
        current:1,
        size: 100,
        activeName:'0',
        teamName:'',
        numberOrName:''
      },
      activeName:'',
      formData:{

      },
      rule:{
        payFees: [
          { required: true, message: '请输入支付施工费', trigger: 'blur' }
        ],
        payExplain: [
          { required: true, message: '请输入附加描述', trigger: 'blur' }
        ],
      }
    }
  },
  mounted(){
    this.loadoneList(this.searchBox)
  },
  methods:{
    payMoney(){
      let aaa = Number(this.formData.payFees)+Number(this.formData.paid)
      if(Number(this.formData.total)- aaa < 0){
        this.$message.error('支付施工费已超出本工程剩余施工费')
        this.formData.payFees = ''
      }
    },
    handleClick(tab){
      this.activeName = tab.index
      if(tab.index==0){
        this.searchBox={
          current: 1,
          teamName:'',
          activeName:'0',
          numberOrName:''
        }
       this.loadoneList(this.searchBox)
      }else {
        this.searchBox={
          current: 1,
          teamName:'',
          activeName:'1',
          numberOrName:''
        }
        this.searchBox.current = 1
       this.loadtwoList(this.searchBox)
      }
    },
    exportFile(){
      paymentRecordExport({id:this.expordId}).then(res=>{
        exportMethod(res,'明细')
      })
    },
    ext(){
      if(this.searchBox.activeName==0){
        paymentingListExport({id:this.expordId}).then(res=>{
          exportMethod(res,'放款中列表')
        })
      }else {
        paymentDoneListExport({id:this.expordId}).then(res=>{
          exportMethod(res,'放款完成列表')
        })
      }
    },
    loadoneList(obj){
      paymentingList(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    loadtwoList(obj){
      paymentCompleteList(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    showDetil(row){
      this.detildialogVisible = true
      this.expordId = row.id
      paymentRecord({id:row.id}).then(res=>{
        this.detiltableData = res.data
      })
    },
    makesure(row,type){
      this.$prompt('请确认当前已完成放款，提交后不可更改 ', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type:'warning',
        inputPlaceholder:'请输入放款完成说明',
        inputValidator:(value)=>{
          if(value){
            return true
          }else {
            return false
          }
        },
        inputErrorMessage: '放款完成说明不能为空'
      }).then(({ value }) => {
        // 成功的操作
        paymentComplete({id:row.id,completeExplain:value}).then(res=>{
          if(res.code===200){
            this.$message.success(res.data)
            this.loadoneList()
          }else {
            this.$message.error(res.data)
          }
        })
      })
    },
    sumit(formData){
      this.$refs[formData].validate((valid) => {
        if (!valid) return false
        paymentSave(this.formData).then(res=>{
          if(res.code===200){
            this.$message.success(res.data)
            this.loadoneList(this.searchBox)
            this.dialogVisible = false
          }else {
            this.$message.error(res.data)
          }
        })
      })
    },
    closeFrom(form) {
      this.$refs[form].clearValidate();
    },
    handleSizeChange(val){
      this.searchBox.current = 1
      this.searchBox.size = val
      if(this.activeName==0){
        this.loadoneList(this.searchBox)

      }else {
        this.loadtwoList(this.searchBox)

      }

    },
    handleCurrentChange(val){
      this.searchBox.current = val
      if(this.activeName==0){
        this.loadoneList(this.searchBox)

      }else {
        this.loadtwoList(this.searchBox)

      }
    },
    search(){
      this.searchBox.current = 1
      if(this.activeName==0){
        this.loadoneList(this.searchBox)
      }else {
        this.loadtwoList(this.searchBox)

      }
    },
    add(row){
      this.dialogVisible = true
      this.formData =  row
      this.formData.inspectionId = row.id
    }
  }
}
</script>

<style scoped>
.SearchBar {
  border-radius: 5px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.content{
  width: 100%;
  background: #f3f4f8;
}
.contentbox {
  margin: 10px;
  padding: 10px;
  background-color:#fff;
  position: relative;
}
</style>